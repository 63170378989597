<template>
  <v-dialog
    v-model="isEditMember"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('editMemberCard') }}
      </v-card-title>
      <v-form
        ref="formEditMember"
        @submit.prevent="updateMember"
      >
        <v-card-text>
          <v-autocomplete
            v-model="dataEditLocal.type_id"
            :items="memberTypeList"
            :label="$t('memberType')"
            item-text="name"
            :rules="[required]"
            outlined
            dense
            :no-data-text="$t('no_information')"
            item-value="id"
          ></v-autocomplete>

          <v-text-field
            v-model="dataEditLocal.name"
            :rules="[required]"
            class="mt-3"
            outlined
            :label="$t('nameMemberCard')"
            dense
          ></v-text-field>
          <v-text-field
            v-model="dataEditLocal.price"
            :rules="[required]"
            outlined
            type="number"
            class="mt-3"
            :label="$t('price')"
            dense
          ></v-text-field>
          <v-text-field
            v-model="dataEditLocal.discount"
            :rules="[required]"
            class="mt-3"
            outlined
            type="number"
            :label="$t('discount')"
            dense
          ></v-text-field>
          <v-text-field
            v-model="dataEditLocal.point"
            :rules="[required]"
            class="mt-3"
            outlined
            type="number"
            :label="$t('point')"
            dense
          >
            <template v-slot:label>
              <span class="error--text">{{ $t('points_earned_1_point') }}</span>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading || dataEditLocal.price<=0||dataEditLocal.point<=0"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-edit-member', false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import member from '@/api/member/member'
import store from '@/store'

export default {
  model: {
    prop: 'isEditMember',
    event: 'update:is-edit-member',
  },
  props: {
    isEditMember: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
    memberTypeList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const dataEditLocal = ref({})
    const formEditMember = ref(null)
    const loading = ref(false)

    const { memberUpdate } = member

    const updateMember = () => {
      const isFormValid = formEditMember.value.validate()
      if (!isFormValid) return
      loading.value = true
      memberUpdate(dataEditLocal.value).then(res => {
        store.commit('add/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-member', false)
        emit('onUpdate')
        loading.value = false
      })
    }

    watch(() => props.isEditMember, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
        dataEditLocal.value.discount = String(dataEditLocal.value.discount)
      }
    })

    return {
      loading,
      formEditMember,
      required,
      updateMember,
      dataEditLocal,
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
