<template>
  <v-card>
    <v-card-title class="px-2">
      {{ $t('memberCard') }} <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="me-2 d-none d-md-block"
        @click="isAddNewMember = true"
      >
        <v-icon class="me-1">
          {{ icons.mdiPlus }}
        </v-icon> {{ $t('addMemberCard') }}
      </v-btn>
      <v-btn
        color="primary"
        fab
        outlined
        small
        class="me-2 d-block d-md-none"
        @click="isAddNewMember = true"
      >
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
      <v-btn
        color="info"
        outlined
        class="d-none d-md-block pt-2"
        :to="{name:'MemberType'}"
      >
        <v-icon
          class="me-1"
        >
        </v-icon> {{ $t('memberType') }}
      </v-btn>
      <v-btn
        color="info"
        outlined
        fab
        small
        class="d-block d-md-none pt-2"
        :to="{name:'MemberType'}"
      >
        <v-icon>
          {{ icons.mdiCardAccountDetailsStarOutline }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-row class="px-2">
      <v-col
        cols="12"
        md="6"
        lg="4"
        class="py-0"
      >
        <v-text-field
          v-model="searchtext"
          :label="$t('search')"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
        class="py-0"
      >
        <v-autocomplete
          v-model.trim="member_type_id"
          :items="memberTypeList"
          :label="$t('memberType')"
          outlined
          dense
          item-text="name"
          :no-data-text="$t('no_information')"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
        md="6"
        lg="4"
      >
        <v-select
          v-model.trim="status"
          :items="statusList"
          :label="$t('status')"
          :item-text="$i18n.locale"
          outlined
          dense
          item-value="value"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :server-items-length="totalDataTableList"
      :footer-props="footer"
      :loading="loading"
      hide-default-footer
      disable-sort
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.id`]="{ index }">
        {{ segmentId + index + 1 }}
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{ item.price | formatPrice }}
      </template>
      <template v-slot:[`item.member_create`]="{ item }">
        <ConverseDate :date="item.member_create" />
      </template>
      <template v-slot:[`item.point`]="{ item }">
        {{ item.point| formatPrice }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <StatusBlock :status="item.status==1?'1':'2'" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          color="#212121"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              @click="dataEdit = item;isEditMember = true"
              v-on="on"
            >
              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("edit") }}</span>
        </v-tooltip>
        <v-tooltip
          color="#212121"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              @click="id=item.id;statusUpdate = item.status ;isUpdateStatus= true"
              v-on="on"
            >
              <v-icon>{{ item.status==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.status==1 ?$t("suspend"):$t('normal') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <Pagination
      :page-data="options.page"
      :page-count="totalPage"
      :segment-id="+segmentId "
      :count-list="dataTableList.length"
      :total="+totalDataTableList"
      @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
      @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
    />
    <AddNewMember
      v-model="isAddNewMember"
      :member-type-list="memberTypeList"
      @onAdd="fetchDataTable('add')"
    />
    <EditMember
      v-model="isEditMember"
      :data-edit="dataEdit"
      :member-type-list="memberTypeList"
      @onUpdate="fetchDataTable('update')"
    />
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ statusUpdate ==1?$t('confirmSuspendMember'):$t('cancelSuspendMember') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loadingUpdate"
            :disabled="loadingUpdate"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiAlertOutline, mdiCardAccountDetailsStarOutline,
} from '@mdi/js'
import useMemberList from './useMemberList'
import AddNewMember from './AddNewMember.vue'
import EditMember from './EditMember.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { formatPrice } from '@/plugins/filters'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  filters: {
    formatPrice,
  },
  components: {
    AddNewMember,
    EditMember,
    StatusBlock,
    ConverseDate,
    Pagination,
  },
  setup() {
    return {

      ...useMemberList(),
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCheck,
        mdiAlertOutline,
        mdiCardAccountDetailsStarOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
