import basicStatus from '@/@fake-db/data/basicStatus.json'
import member from '@/api/member/member'
import memberType from '@/api/member/memberType'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useMemberList() {
  const dataTableList = ref([])
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'id',
      width: 50,
    },
    {
      text: i18n.t('list'),
      value: 'name',
      width: 200,
    },
    {
      text: `${i18n.t('price')}`,
      value: 'price',
      align: 'end',
      width: 100,
    },
    {
      text: `${i18n.t('point')}`,
      value: 'point',
      align: 'end',
      width: 100,
    },
    {
      text: `${i18n.t('discount')} (%)`,
      width: 120,
      value: 'discount',
    },
    {
      text: i18n.t('date'),
      value: 'member_create',
      width: 140,
    },
    {
      text: i18n.t('status'),
      value: 'status',
      width: 100,
    },
    {
      text: i18n.t('memberType'),
      value: 'type_name',
      width: 170,
    },
    {
      text: i18n.t('user_is_list'),
      value: 'user_fullname',
      width: 150,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center',
      width: 120,
    },
  ])
  const options = ref({}) // Consider initializing this object with default pagination properties
  const footer = ref(i18n.locale === 'en' ? footerDataTablesEN : footerDataTables)
  const loading = ref(false)

  const memberTypeList = ref([])
  const member_type_id = ref('')

  const isAddNewMember = ref(false)
  const isEditMember = ref(false)
  const dataEdit = ref({})
  const totalDataTableList = ref(0)
  const totalPage = ref(0)

  const statusList = ref(basicStatus.data) // Ensure 'basicStatus' is defined
  const status = ref(1)
  const searchtext = ref('')
  const segmentId = ref(0)

  const id = ref('')
  const statusUpdate = ref('')
  const isUpdateStatus = ref(false)
  const loadingUpdate = ref(false)

  const { memberList, memberStatusUpdate } = member // Assuming 'member' object is imported or defined
  const { memberTypeList: memberTypeListGet } = memberType // Assuming 'memberType' object is imported or defined

  // This hook fetches data when the component is mounted
  onMounted(() => {
    fetchDataTable()
  })

  // This watcher monitors changes in reactive variables and triggers data fetching accordingly
  watch([searchtext, member_type_id, status, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1] || newvalue[2] !== oldvalue[2]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  // Fetch member type list and update the 'memberTypeList' variable
  memberTypeListGet({
    searchtext: '',
    status: '1',
  }).then(res => {
    memberTypeList.value = res
  })

  // Function to update member status
  const updateStatus = () => {
    loadingUpdate.value = true
    memberStatusUpdate({
      id: id.value,
      status: statusUpdate.value == 1 ? '0' : '1',
    }).then(res => {
      // Assuming 'store.commit' is correctly set up
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      isUpdateStatus.value = false
      loadingUpdate.value = false
      fetchDataTable('delete')
    })
  }

  // Function to fetch data table based on specified criteria
  const fetchDataTable = event => {
    loading.value = true
    memberList({
      searchtext: searchtext.value,
      member_type_id: member_type_id.value,
      status: status.value == 2 ? '0' : status.value == 1 ? '1' : '',
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data // Assuming 'dataTableList' is defined elsewhere
      totalDataTableList.value = count
      segmentId.value = segment
      totalPage.value = count_of_page

      if (event == 'add') {
        options.value.page = count_of_page
      } else if (event == 'delete') {
        if (+options.value.page >= +count_of_page) {
          options.value.page = count_of_page
        }
      }
      loading.value = false
    })
  }

  return {
    isEditMember,
    dataTableList, // Make sure 'dataTableList' is defined elsewhere
    columns, // Ensure 'columns' is defined
    options,
    footer,
    loading,
    memberTypeList,
    member_type_id,
    isAddNewMember,
    statusList,
    status,
    searchtext,
    totalPage,
    dataEdit,
    totalDataTableList,
    id,
    statusUpdate,
    isUpdateStatus,
    loadingUpdate,
    updateStatus,
    fetchDataTable,
    segmentId,
  }
}
