<template>
  <v-dialog
    v-model="isAddNewMember"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('addMemberCard') }}
      </v-card-title>
      <v-form
        ref="formAddMember"
        @submit.prevent="addMember"
      >
        <v-card-text>
          <v-autocomplete
            v-model="type_id"
            :items="memberTypeList"
            :label="$t('memberType')"
            item-text="name"
            outlined
            hide-details="auto"
            dense
            :no-data-text="$t('no_information')"
            item-value="id"
          ></v-autocomplete>

          <v-text-field
            v-model="name"
            :rules="[required]"
            class="mt-6"
            hide-details="auto"
            outlined
            :label="$t('nameMemberCard')"
            dense
          ></v-text-field>
          <v-text-field
            v-model="price"
            :rules="[required]"
            outlined
            type="number"
            hide-details="auto"
            class="mt-6"
            :label="$t('price')"
            dense
          ></v-text-field>
          <v-text-field
            v-model="discount"
            :rules="[required]"
            class="mt-6"
            hide-details="auto"
            outlined
            type="number"
            :label="$t('discount')"
            dense
          ></v-text-field>
          <v-text-field
            v-model="point"
            :rules="[required]"
            class="mt-6"
            hide-details="auto"
            outlined
            type="number"
            dense
          >
            <template v-slot:label>
              <span class="error--text">{{ $t('points_earned_1_point') }}</span>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading || price<=0||point<=0"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-add-new-member', false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import member from '@/api/member/member'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewMember',
    event: 'update:is-add-new-member',
  },
  props: {
    isAddNewMember: {
      type: Boolean,
      default: false,
    },
    memberTypeList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const type_id = ref('')
    const name = ref('')
    const price = ref('0')
    const discount = ref('0')
    const point = ref('0')
    const formAddMember = ref(null)
    const loading = ref(false)
    const { memberAdd } = member

    watch(() => props.memberTypeList, value => {
      if (value.length > 0) {
        type_id.value = value[0].id
      }
    })

    const addMember = () => {
      const isFormValid = formAddMember.value.validate()
      if (!isFormValid) return
      loading.value = true
      memberAdd({
        type_id: type_id.value,
        name: name.value,
        price: price.value,
        discount: discount.value,
        point: point.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-member', false)
        emit('onAdd')
        loading.value = false
      })
    }

    watch(() => props.isAddNewMember, value => {
      if (value <= 0) {
        type_id.value = ''
        name.value = ''
        price.value = '0'
        discount.value = '0'
        point.value = '0'
      }
    })

    return {
      formAddMember,
      type_id,
      required,
      addMember,
      price,
      name,
      loading,
      discount,
      point,
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
